import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectEntry } from '../../components/MultiSelect/types';
import { getMerchantListThunk } from '../thunks/Merchant/getMerchantListThunk';
import { getNewTransfersThunk } from '../thunks/NewTransfer/getNewTransfersThunk';
import { getNewWithdrawBankTransfersThunk } from '../thunks/NewWithdrawBankTransfer/getNewWithdrawBankTransfersThunk';
import { getNewWithdrawPaparaTransfersThunk } from '../thunks/NewWithdrawPaparaTransfer/getNewWithdrawPaparaTransfersThunk';
import { getNewWithdrawPayfixTransfersThunk } from '../thunks/NewWithdrawPayfixTransfer/getNewWithdrawPayfixTransfersThunk';
import { getNewWithdrawMFTTransfersThunk } from '../thunks/NewWithdrawMFTTransfer/getNewWithdrawMFTTransfersThunk';
import { getNewWithdrawCryptoTransfersThunk } from '../thunks/NewWithdrawCryptoTransfer/getNewWithdrawCryptoTransfersThunk';
import { getNewPaparaTransfersThunk } from '../thunks/NewPaparaTransfer/getNewPaparaTransfersThunk';
import { getNewPayfixTransfersThunk } from '../thunks/NewPayfixTransfer/getNewPayfixTransfersThunk';
import { getNewMFTTransfersThunk } from '../thunks/NewMFTTransfer/getNewMFTTransfersThunk';
import { getNewCryptoTransfersThunk } from '../thunks/NewCryptoTransfer/getNewCryptoTransfersThunk';
import { getDepositsThunk } from '../thunks/Deposit/getDepositsThunk';
import { getWithdrawsBankThunk } from '../thunks/WithdrawBank/getWithdrawsBankThunk';
import { getWithdrawBankInfoThunk } from '../thunks/WithdrawBank/getWithdrawBankInfoThunk';
import { getWithdrawsPaparaThunk } from '../thunks/WithdrawPapara/getWithdrawsPaparaThunk';
import { getWithdrawPaparaInfoThunk } from '../thunks/WithdrawPapara/getWithdrawPaparaInfoThunk';
import { getWithdrawsPayfixThunk } from '../thunks/WithdrawPayfix/getWithdrawsPayfixThunk';
import { getWithdrawPayfixInfoThunk } from '../thunks/WithdrawPayfix/getWithdrawPayfixInfoThunk';
import { getWithdrawsMFTThunk } from '../thunks/WithdrawMFT/getWithdrawsMFTThunk';
import { getWithdrawMFTInfoThunk } from '../thunks/WithdrawMFT/getWithdrawMFTInfoThunk';
import { getWithdrawsCryptoThunk } from '../thunks/WithdrawCrypto/getWithdrawsCryptoThunk';
import { getWithdrawCryptoInfoThunk } from '../thunks/WithdrawCrypto/getWithdrawCryptoInfoThunk';
import { getPaparaMailsThunk } from '../thunks/PaparaMail/getPaparaMailsThunk';
import { getReportsThunk } from '../thunks/Report/getReportsThunk';
import { getCryptoRegistersThunk } from '../thunks/CryptoRegisters/getCryptoRegistersThunk';
import { getCryptoRegisterListThunk } from '../thunks/CryptoRegisters/getCryptoRegisterListThunk';
import { getPaparaRegistersThunk } from '../thunks/PaparaRegister/getPaparaRegistersThunk';
import { getPaparaRegisterListThunk } from '../thunks/PaparaRegister/getPaparaRegisterListThunk';
import { getPayFixRegistersThunk } from '../thunks/PayFix/getPayFixRegistersThunk';
import { getPayFixRegisterListThunk } from '../thunks/PayFix/getPayFixRegisterListThunk';
import { getMFTRegistersThunk } from '../thunks/MFTRegister/getMFTRegistersThunk';
import { getMFTRegisterListThunk } from '../thunks/MFTRegister/getMFTRegisterListThunk';
import { getBankRegistersThunk } from '../thunks/BankRegister/getBankRegistersThunk';

import {
  DataTableValue,
  DataTableValueInitial,
} from '../../components/DataTable/types';
import { PaparaRegisterInfoFields, PaparaRegisterInfoInitial} from '../../pages/PaparaRegisters/types';
import { getPaparaRegisterDetailsThunk } from '../thunks/PaparaRegister/getPaparaRegisterDetailsThunk';
import { getPaparaRegisterInfoThunk } from '../thunks/PaparaRegister/getPaparaRegisterInfoThunk';
import { getCryptoRegisterInfoThunk } from '../thunks/CryptoRegisters/getCryptoRegisterInfoThunk';
import { getMFTRegisterDetailsThunk } from '../thunks/MFTRegister/getMFTRegisterDetailsThunk';
import { getMFTRegisterInfoThunk } from '../thunks/MFTRegister/getMFTRegisterInfoThunk';



import { PayFixRegisterInfoFields, PayFixRegisterInfoInitial} from '../../pages/Payfix/types';
import { getPayFixRegisterDetailsThunk } from '../thunks/PayFix/getPayFixRegisterDetailsThunk';
import { getPayFixRegisterInfoThunk } from '../thunks/PayFix/getPayFixRegisterInfoThunk';


import {
  MFTRegisterInfoFields,
  MFTRegisterInfoInitial,
} from '../../pages/MFTRegisters/types';

import {
  CryptoRegisterInfoFields,
  CryptoRegisterInfoInitial,
} from '../../pages/CryptoRegisters/types';
import { getBankRegisterListThunk } from '../thunks/BankRegister/getBankRegisterListThunk';
import { getBankListThunk } from '../thunks/Bank/getBankListThunk';
import { getBankRegisterDetailsThunk } from '../thunks/BankRegister/getBankRegisterDetailsThunk';
import {
  BankRegisterInfoFields,
  BankRegisterInfoInitial,
} from '../../pages/BankRegisters/types';
import { getBankRegisterInfoThunk } from '../thunks/BankRegister/getBankRegisterInfoThunk';
import { getMerchantsThunk } from '../thunks/Merchant/getMerchantsThunk';
import { getUsersThunk } from '../thunks/User/getUsersThunk';
import { getUserGroupListThunk } from '../thunks/User/getUserGroupListThunk';
import { getRoleListThunk } from '../thunks/Role/getRoleListThunk';
import { getRolesThunk } from '../thunks/Role/getRolesThunk';
import { getRoleInfoThunk } from '../thunks/Role/getRoleInfoThunk';
import { RoleInfoFields, RoleInfoInitial } from '../../pages/Roles/types';
import { getBanksThunk } from '../thunks/Bank/getBanksThunk';
import {
  MerchantInfoFields,
  MerchantInfoInitial,
} from '../../pages/Merchants/types';
import { getMerchantInfoThunk } from '../thunks/Merchant/getMerchantInfoThunk';
import { getFraudsThunk } from '../thunks/Fraud/getFraudsThunk';
import { getBankSetThunk } from '../thunks/BanksSet/getBankSetThunk';
import { UserInfoFields, UserInfoInitial } from '../../pages/Users/types';
import { getUserInfoThunk } from '../thunks/User/getUserInfoThunk';
import { getWholeMerchantListThunk } from '../thunks/Merchant/getWholeMerchantListThunk';
import { getWholeUserListThunk } from '../thunks/User/getWholeUserListThunk';
import {
  CurrentPapara,
  CurrentPaparaInitial,
} from '../../pages/CurrentPaparaList/types';
import { getCurrentPaparaListThunk } from '../thunks/CurrentPaparaList/getCurrentPaparaListThunk';
import {
  WithdrawPaparaInfoFields,
  WithdrawPaparaInfoInitial,
} from '../../pages/WithdrawsPapara/types';
import {
  WithdrawPayfixInfoFields,
  WithdrawPayfixInfoInitial,
} from '../../pages/WithdrawsPayfix/types';
import {
  WithdrawMFTInfoFields,
  WithdrawMFTInfoInitial,
} from '../../pages/WithdrawsMFT/types';
import {
  WithdrawBankInfoFields,
  WithdrawBankInfoInitial,
} from '../../pages/WithdrawsBank/types';

import {
  WithdrawCryptoInfoFields,
  WithdrawCryptoInfoInitial,
} from '../../pages/WithdrawsCrypto/types';

import {
  DepositInfoFields,
  DepositInfoInitial,
} from '../../pages/Deposits/types';
import { getDepositInfoThunk } from '../thunks/Deposit/getDepositInfoThunk';
import {
  DashboardInfoFields,
  DashboardInfoInitial,
} from '../../pages/Dashboard/types';
import { getDashboardInfoThunk } from '../thunks/Dashboard/getDashboardInfoThunk';
import {
  TransferItemEntry,
  TransferItemInfoFields,
  TransferItemInfoInitial,
} from '../../pages/NewTransfer/types';
import {
  PaparaTransferItemInfoFields,
  PaparaTransferItemInfoInitial,
} from '../../pages/NewPaparaTransfer/types';
import {
  MFTTransferItemInfoFields,
  MFTTransferItemInfoInitial,
} from '../../pages/NewMFTTransfer/types';
import {
  CryptoTransferItemInfoFields,
  CryptoTransferItemInfoInitial,
  TransferItemEntry as TransferCryptoItemEntry,
} from '../../pages/NewCryptoTransfer/types';
import { getNewTransferInfoThunk } from '../thunks/NewTransfer/getNewTransferInfoThunk';
import { getNewTransferBankRegistersThunk } from '../thunks/NewTransfer/getNewTransferBankRegistersThunk';
import { getNewPaparaTransferInfoThunk } from '../thunks/NewPaparaTransfer/getNewPaparaTransferInfoThunk';
import { getNewPaparaTransferRegistersThunk } from '../thunks/NewPaparaTransfer/getNewPaparaTransferRegistersThunk';
import { getNewPayfixTransferInfoThunk } from '../thunks/NewPayfixTransfer/getNewPayfixTransferInfoThunk';
import { getNewPayfixTransferRegistersThunk } from '../thunks/NewPayfixTransfer/getNewPayfixTransferRegistersThunk';

import { getNewMFTTransferInfoThunk } from '../thunks/NewMFTTransfer/getNewMFTTransferInfoThunk';
import { getNewMFTTransferRegistersThunk } from '../thunks/NewMFTTransfer/getNewMFTTransferRegistersThunk';
import { getNewCryptoTransferInfoThunk } from '../thunks/NewCryptoTransfer/getNewCryptoTransferInfoThunk';
import { getNewCryptoTransferRegistersThunk } from '../thunks/NewCryptoTransfer/getNewCryptoTransferRegistersThunk';
import { getCryptoRegisterDetailsThunk } from '../thunks/CryptoRegisters/getCryptoRegisterDetailsThunk';


import { getNewCreditCardTransfersThunk } from '../thunks/NewCreditCardTransfer/getNewCreditCardTransfersThunk';
import { getCreditCardRegistersThunk } from '../thunks/CreditCardRegisters/getCreditCardRegistersThunk';
import { getCreditCardRegisterListThunk } from '../thunks/CreditCardRegisters/getCreditCardRegisterListThunk';
import { getCreditCardRegisterInfoThunk } from '../thunks/CreditCardRegisters/getCreditCardRegisterInfoThunk';
import {
  CreditCardRegisterInfoFields,
  CreditCardRegisterInfoInitial,
} from '../../pages/CreditCardRegisters/types';
import {
  WithdrawCreditCardInfoFields,
  WithdrawCreditCardInfoInitial,
} from '../../pages/WithdrawsCreditCard/types';
import {
  CreditCardTransferItemInfoFields,
  CreditCardTransferItemInfoInitial,
  TransferItemEntry as TransferCreditCardItemEntry,
} from '../../pages/NewCreditCardTransfer/types';
import { getNewCreditCardTransferInfoThunk } from '../thunks/NewCreditCardTransfer/getNewCreditCardTransferInfoThunk';
import { getNewCreditCardTransferRegistersThunk } from '../thunks/NewCreditCardTransfer/getNewCreditCardTransferRegistersThunk';
import { getCreditCardRegisterDetailsThunk } from '../thunks/CreditCardRegisters/getCreditCardRegisterDetailsThunk';
import { getWithdrawsCreditCardThunk } from '../thunks/WithdrawCreditCard/getWithdrawsCreditCardThunk';
import { getWithdrawCreditCardInfoThunk } from '../thunks/WithdrawCreditCard/getWithdrawCreditCardInfoThunk';
import { getNewWithdrawCreditCardTransfersThunk } from '../thunks/NewWithdrawCreditCardTransfer/getNewWithdrawCreditCardTransfersThunk';
import { PayfixTransferItemInfoFields, PayfixTransferItemInfoInitial } from '../../pages/NewPayfixTransfer/types';

interface globalState {
  dashboardInfo: DashboardInfoFields;
  currentPaparaList: CurrentPapara;
  fraudList: DataTableValue;
  fraudListLoading: boolean;
  roleSelectList: SelectEntry[];
  userGroupSelectList: SelectEntry[];
  roleList: DataTableValue;
  roleListLoading: boolean;
  roleInfo: RoleInfoFields;
  userList: DataTableValue;
  userInfo: UserInfoFields;
  userListLoading: boolean;
  merchantList: DataTableValue;
  merchantSelectList: SelectEntry[];
  merchantWholeSelectList: SelectEntry[];
  userWholeSelectList: SelectEntry[];
  merchantListLoading: boolean;
  merchantInfo: MerchantInfoFields;
  paparaRegisterSelectList: SelectEntry[];
  paparaRegisterSelectListDeposit: SelectEntry[];
  paparaRegisterSelectListWithdraw: SelectEntry[];
  payfixRegisterSelectList: SelectEntry[];
  payfixRegisterSelectListDeposit: SelectEntry[];
  payfixRegisterSelectListWithdraw: SelectEntry[];
  mftRegisterSelectList: SelectEntry[];
  mftRegisterSelectListDeposit: SelectEntry[];
  mftRegisterSelectListWithdraw: SelectEntry[];
  cryptoRegisterSelectList: SelectEntry[];
  cryptoRegisterSelectListDeposit: SelectEntry[];
  cryptoRegisterSelectListWithdraw: SelectEntry[];
  bankRegisterSelectList: SelectEntry[];
  bankRegisterSelectListDeposit: SelectEntry[];
  bankRegisterSelectListWithdraw: SelectEntry[];
  bankSelectList: SelectEntry[];
  bankList: DataTableValue;
  bankListLoading: boolean;
  newTransferList: TransferItemEntry[];
  newPaparaTransferList: TransferItemEntry[];
  newPayfixTransferList: TransferItemEntry[];
  newMFTTransferList: TransferItemEntry[];
  newCryptoTransferList: TransferCryptoItemEntry[];
  newWithdrawBankTransferList: TransferItemEntry[];
  newWithdrawPaparaTransferList: TransferItemEntry[];
  newWithdrawPayfixTransferList: TransferItemEntry[];
  newWithdrawMFTTransferList: TransferItemEntry[];
  newWithdrawCryptoTransferList: TransferItemEntry[];
  newTransferInfo: TransferItemInfoFields;
  newPaparaTransferInfo: PaparaTransferItemInfoFields;
  newPayfixTransferInfo: PayfixTransferItemInfoFields;
  newMFTTransferInfo: MFTTransferItemInfoFields;
  newCryptoTransferInfo: CryptoTransferItemInfoFields;
  newTransferBankRegisters: DataTableValue;
  newTransferBankRegistersLoading: boolean;
  newTransferCryptoRegisters: DataTableValue;
  newTransferCryptoRegistersLoading: boolean;
  newTransferPaparaRegisters: DataTableValue;
  newTransferPaparaRegistersLoading: boolean;
  newTransferPayfixRegisters: DataTableValue;
  newTransferPayfixRegistersLoading: boolean;
  newTransferMFTRegisters: DataTableValue;
  newTransferMFTRegistersLoading: boolean;
  depositList: DataTableValue;
  depositListLoading: boolean;
  depositInfo: DepositInfoFields;
  withdrawList: DataTableValue;
  withdrawListLoading: boolean;
  withdrawMFTList: DataTableValue;
  withdrawMFTListLoading: boolean;
  withdrawBankList: DataTableValue;
  withdrawBankListLoading: boolean;
  withdrawPaparaInfo: WithdrawPaparaInfoFields;
  withdrawMFTInfo: WithdrawMFTInfoFields;
  withdrawBankInfo: WithdrawBankInfoFields;
  withdrawCryptoList: DataTableValue;
  withdrawCryptoInfo: WithdrawCryptoInfoFields;
  withdrawPayfixList: DataTableValue;
  withdrawPayfixInfo: WithdrawPayfixInfoFields;
  withdrawInfoLoading: boolean;
  withdrawBankInfoLoading: boolean;
  withdrawCryptoInfoLoading: boolean;
  withdrawCryptoListLoading: boolean;
  withdrawPayfixInfoLoading: boolean;
  withdrawPayfixListLoading: boolean;
  paparaMailList: DataTableValue;
  paparaMailListLoading: boolean;
  reportList: DataTableValue;
  reportListLoading: boolean;
  paparaRegisterList: DataTableValue;
  paparaRegisterDetailsList: DataTableValue;
  paparaRegisterInfo: PaparaRegisterInfoFields;
  paparaRegisterListLoading: boolean;
  paparaRegisterDetailsLoading: boolean;
  payfixRegisterList: DataTableValue;
  payfixRegisterDetailsList: DataTableValue;
  payfixRegisterInfo: PayFixRegisterInfoFields;
  payfixRegisterListLoading: boolean;
  payfixRegisterDetailsLoading: boolean;
  mftRegisterList: DataTableValue;
  mftRegisterDetailsList: DataTableValue;
  mftRegisterInfo: MFTRegisterInfoFields;
  mftRegisterListLoading: boolean;
  mftRegisterDetailsLoading: boolean;
  cryptoRegisterList: DataTableValue;
  cryptoRegisterDetailsList: DataTableValue;
  cryptoRegisterInfo: CryptoRegisterInfoFields;
  cryptoRegisterListLoading: boolean;
  cryptoRegisterDetailsLoading: boolean;
  bankRegisterList: DataTableValue;
  bankRegisterDetailsList: DataTableValue;
  bankRegisterInfo: BankRegisterInfoFields;
  bankRegisterListLoading: boolean;
  bankRegisterDetailsLoading: boolean;
  newTransferCreated: boolean;
  newTransferStatusChanged: boolean;
  newPaparaTransferCreated: boolean;
  newPaparaTransferStatusChanged: boolean;
  newPayfixTransferCreated: boolean;
  newPayfixTransferStatusChanged: boolean;
  newMFTTransferCreated: boolean;
  newMFTTransferStatusChanged: boolean;
  newCryptoTransferCreated: boolean;
  newCryptoTransferStatusChanged: boolean;
  newWithdrawBankTransferCreated: boolean;
  newWithdrawBankTransferStatusChanged: boolean;
  newWithdrawPaparaTransferCreated: boolean;
  newWithdrawPaparaTransferStatusChanged: boolean;
  newWithdrawPayfixTransferCreated: boolean;
  newWithdrawPayfixTransferStatusChanged: boolean;
  newWithdrawMFTTransferCreated: boolean;
  newWithdrawMFTTransferStatusChanged: boolean;
  newWithdrawCryptoTransferCreated: boolean;
  newWithdrawCryptoTransferStatusChanged: boolean;
  banksSetList: DataTableValue;
  banksSetListLoading: boolean;
  creditCardRegisterSelectList: SelectEntry[];
  creditCardRegisterSelectListDeposit: SelectEntry[];
  creditCardRegisterSelectListWithdraw: SelectEntry[];
  newCreditCardTransferList: TransferCreditCardItemEntry[];
  creditCardRegisterList: DataTableValue;
  creditCardRegisterDetailsList: DataTableValue;
  creditCardRegisterInfo: CreditCardRegisterInfoFields;
  creditCardRegisterListLoading: boolean;
  creditCardRegisterDetailsLoading: boolean;
  newWithdrawCreditCardTransferList: TransferItemEntry[];
  newTransferCreditCardRegisters: DataTableValue;
  newTransferCreditCardRegistersLoading: boolean;
  withdrawCreditCardList: DataTableValue;
  withdrawCreditCardInfo: WithdrawCreditCardInfoFields;
  withdrawCreditCardInfoLoading: boolean;
  withdrawCreditCardListLoading: boolean;
  newCreditCardTransferCreated: boolean;
  newCreditCardTransferStatusChanged: boolean;
  newWithdrawCreditCardTransferCreated: boolean;
  newWithdrawCreditCardTransferStatusChanged: boolean;
  newCreditCardTransferInfo: CreditCardTransferItemInfoFields;
}

const initialState: globalState = {
  dashboardInfo: DashboardInfoInitial,
  currentPaparaList: CurrentPaparaInitial,
  fraudList: DataTableValueInitial,
  fraudListLoading: false,
  roleSelectList: [],
  userGroupSelectList: [],
  roleList: DataTableValueInitial,
  roleListLoading: false,
  roleInfo: RoleInfoInitial,
  userList: DataTableValueInitial,
  userListLoading: false,
  userInfo: UserInfoInitial,
  merchantList: DataTableValueInitial,
  merchantSelectList: [],
  merchantWholeSelectList: [],
  userWholeSelectList: [],
  merchantListLoading: false,
  merchantInfo: MerchantInfoInitial,
  paparaRegisterSelectList: [],
  paparaRegisterSelectListDeposit: [],
  paparaRegisterSelectListWithdraw: [],
  payfixRegisterSelectList: [],
  payfixRegisterSelectListDeposit: [],
  payfixRegisterSelectListWithdraw: [],
  mftRegisterSelectList: [],
  mftRegisterSelectListDeposit: [],
  mftRegisterSelectListWithdraw: [],
  cryptoRegisterSelectList: [],
  cryptoRegisterSelectListDeposit: [],
  cryptoRegisterSelectListWithdraw: [],
  bankRegisterSelectList: [],
  bankRegisterSelectListDeposit: [],
  bankRegisterSelectListWithdraw: [],
  bankSelectList: [],
  bankList: DataTableValueInitial,
  bankListLoading: false,
  newTransferList: [],
  newPaparaTransferList: [],
  newPayfixTransferList: [],
  newMFTTransferList: [],
  newCryptoTransferList: [],
  newWithdrawBankTransferList: [],
  newWithdrawPaparaTransferList: [],
  newWithdrawPayfixTransferList: [],
  newWithdrawMFTTransferList: [],
  newWithdrawCryptoTransferList: [],
  newTransferInfo: TransferItemInfoInitial,
  newPaparaTransferInfo: PaparaTransferItemInfoInitial,
  newPayfixTransferInfo: PayfixTransferItemInfoInitial,
  newMFTTransferInfo: MFTTransferItemInfoInitial,
  newCryptoTransferInfo: CryptoTransferItemInfoInitial,
  newTransferBankRegisters: DataTableValueInitial,
  newTransferBankRegistersLoading: false,
  newTransferCryptoRegisters: DataTableValueInitial,
  newTransferCryptoRegistersLoading: false,
  newTransferPaparaRegisters: DataTableValueInitial,
  newTransferPaparaRegistersLoading: false,
  newTransferPayfixRegisters: DataTableValueInitial,
  newTransferPayfixRegistersLoading: false,
  newTransferMFTRegisters: DataTableValueInitial,
  newTransferMFTRegistersLoading: false,
  depositList: DataTableValueInitial,
  depositListLoading: false,
  depositInfo: DepositInfoInitial,
  withdrawList: DataTableValueInitial,
  withdrawListLoading: false,
  withdrawMFTList: DataTableValueInitial,
  withdrawMFTListLoading: false,
  withdrawBankList: DataTableValueInitial,
  withdrawBankListLoading: false,
  withdrawPayfixList: DataTableValueInitial,
  withdrawPayfixInfo: WithdrawPayfixInfoInitial,
  withdrawPaparaInfo: WithdrawPaparaInfoInitial,
  withdrawMFTInfo: WithdrawMFTInfoInitial,
  withdrawBankInfo: WithdrawBankInfoInitial,
  withdrawCryptoList: DataTableValueInitial,
  withdrawCryptoInfo: WithdrawCryptoInfoInitial,
  withdrawInfoLoading: false,
  withdrawBankInfoLoading: false,
  withdrawCryptoInfoLoading: false,
  withdrawCryptoListLoading: false,
  withdrawPayfixInfoLoading: false,
  withdrawPayfixListLoading: false,
  paparaMailList: DataTableValueInitial,
  paparaMailListLoading: false,
  reportList: DataTableValueInitial,
  reportListLoading: false,
  paparaRegisterList: DataTableValueInitial,
  paparaRegisterDetailsList: DataTableValueInitial,
  paparaRegisterInfo: PaparaRegisterInfoInitial,
  paparaRegisterListLoading: false,
  paparaRegisterDetailsLoading: false,
  payfixRegisterList: DataTableValueInitial,
  payfixRegisterDetailsList: DataTableValueInitial,
  payfixRegisterInfo: PayFixRegisterInfoInitial,
  payfixRegisterListLoading: false,
  payfixRegisterDetailsLoading: false,
  mftRegisterList: DataTableValueInitial,
  mftRegisterDetailsList: DataTableValueInitial,
  mftRegisterInfo: MFTRegisterInfoInitial,
  mftRegisterListLoading: false,
  mftRegisterDetailsLoading: false,
  cryptoRegisterList: DataTableValueInitial,
  cryptoRegisterDetailsList: DataTableValueInitial,
  cryptoRegisterInfo: CryptoRegisterInfoInitial,
  cryptoRegisterListLoading: false,
  cryptoRegisterDetailsLoading: false,
  bankRegisterList: DataTableValueInitial,
  bankRegisterDetailsList: DataTableValueInitial,
  bankRegisterInfo: BankRegisterInfoInitial,
  bankRegisterListLoading: false,
  bankRegisterDetailsLoading: false,
  newTransferCreated: false,
  newTransferStatusChanged: false,
  newPaparaTransferCreated: false,
  newPaparaTransferStatusChanged: false,
  newPayfixTransferCreated: false,
  newPayfixTransferStatusChanged: false,
  newMFTTransferCreated: false,
  newMFTTransferStatusChanged: false,
  newCryptoTransferCreated: false,
  newCryptoTransferStatusChanged: false,
  newWithdrawBankTransferCreated: false,
  newWithdrawBankTransferStatusChanged: false,
  newWithdrawPaparaTransferCreated: false,
  newWithdrawPaparaTransferStatusChanged: false,
  newWithdrawPayfixTransferCreated: false,
  newWithdrawPayfixTransferStatusChanged: false,
  newWithdrawMFTTransferCreated: false,
  newWithdrawMFTTransferStatusChanged: false,
  newWithdrawCryptoTransferCreated: false,
  newWithdrawCryptoTransferStatusChanged: false,
  banksSetList: DataTableValueInitial,
  banksSetListLoading: false,
  creditCardRegisterSelectList: [],
  creditCardRegisterSelectListDeposit: [],
  creditCardRegisterSelectListWithdraw: [],
  newCreditCardTransferList: [],
  creditCardRegisterList: DataTableValueInitial,
  creditCardRegisterDetailsList: DataTableValueInitial,
  creditCardRegisterInfo: CreditCardRegisterInfoInitial,
  creditCardRegisterListLoading: false,
  creditCardRegisterDetailsLoading: false,
  newWithdrawCreditCardTransferList: [],
  newTransferCreditCardRegisters: DataTableValueInitial,
  newTransferCreditCardRegistersLoading: false,
  withdrawCreditCardList: DataTableValueInitial,
  withdrawCreditCardInfo: WithdrawCreditCardInfoInitial,
  withdrawCreditCardInfoLoading: false,
  withdrawCreditCardListLoading: false,
  newCreditCardTransferCreated: false,
  newCreditCardTransferStatusChanged: false,
  newWithdrawCreditCardTransferCreated: false,
  newWithdrawCreditCardTransferStatusChanged: false,
  newCreditCardTransferInfo: CreditCardTransferItemInfoInitial
};

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setNewTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newTransferCreated = action.payload;
    },
    setNewTransferStatusChanged: (state, action: PayloadAction<boolean>) => {
      state.newTransferStatusChanged = action.payload;
    },
    setNewWithdrawBankTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawBankTransferCreated = action.payload;
    },
    setNewWithdrawBankTransferStatusChanged: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawBankTransferStatusChanged = action.payload;
    },
    setNewWithdrawPaparaTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawPaparaTransferCreated = action.payload;
    },
    setNewWithdrawPaparaTransferStatusChanged: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawPaparaTransferStatusChanged = action.payload;
    },
    setNewWithdrawPayfixTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawPayfixTransferCreated = action.payload;
    },
    setNewWithdrawPayfixTransferStatusChanged: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawPayfixTransferStatusChanged = action.payload;
    },
    setNewWithdrawCryptoTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawCryptoTransferCreated = action.payload;
    },
    setNewWithdrawCryptoTransferStatusChanged: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawCryptoTransferStatusChanged = action.payload;
    },
    setNewCryptoTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newCryptoTransferCreated = action.payload;
    },
    setNewCryptoTransferStatusChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.newCryptoTransferStatusChanged = action.payload;
    },
    setNewPaparaTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newPaparaTransferCreated = action.payload;
    },
    setNewPaparaTransferStatusChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.newPaparaTransferStatusChanged = action.payload;
    },
    setNewPayfixTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newPayfixTransferCreated = action.payload;
    },
    setNewPayfixTransferStatusChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.newPayfixTransferStatusChanged = action.payload;
    },
    setNewWithdrawCreditCardTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawCreditCardTransferCreated = action.payload;
    },
    setNewWithdrawCreditCardTransferStatusChanged: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawCreditCardTransferStatusChanged = action.payload;
    },
    setNewCreditCardTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newCreditCardTransferCreated = action.payload;
    },
    setNewCreditCardTransferStatusChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.newCreditCardTransferStatusChanged = action.payload;
    },
    setNewWithdrawMFTTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawMFTTransferCreated = action.payload;
    },
    setNewWithdrawMFTTransferStatusChanged: (state, action: PayloadAction<boolean>) => {
      state.newWithdrawMFTTransferStatusChanged = action.payload;
    },
    setNewMFTTransferCreated: (state, action: PayloadAction<boolean>) => {
      state.newMFTTransferCreated = action.payload;
    },
    setNewMFTTransferStatusChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.newMFTTransferStatusChanged = action.payload;
    },
    resetGlobalStore: (state) => {
      return {
        ...initialState,
        newWithdrawBankTransferList: state.newWithdrawBankTransferList,
        newWithdrawPaparaTransferList: state.newWithdrawPaparaTransferList,
        newWithdrawPayfixTransferList: state.newWithdrawPayfixTransferList,
        newWithdrawCryptoTransferList: state.newWithdrawCryptoTransferList,
        newWithdrawCreditCardTransferList: state.newWithdrawCreditCardTransferList,
        newTransferList: state.newTransferList,
        newPaparaTransferList: state.newPaparaTransferList,
        newPayfixTransferList: state.newPayfixTransferList,
        newCryptoTransferList: state.newCryptoTransferList,
        newCreditCardTransferList: state.newCreditCardTransferList,
        fraudListLoading: state.fraudListLoading,
        roleListLoading: state.roleListLoading,
        userListLoading: state.userListLoading,
        merchantListLoading: state.merchantListLoading,
        bankListLoading: state.bankListLoading,
        newTransferBankRegistersLoading: state.newTransferBankRegistersLoading,
        newTransferCryptoRegistersLoading: state.newTransferCryptoRegistersLoading,
        newTransferCreditCardRegistersLoading: state.newTransferCreditCardRegistersLoading,
        newTransferPaparaRegistersLoading: state.newTransferPaparaRegistersLoading,
        newTransferPayfixRegistersLoading: state.newTransferPayfixRegistersLoading,
        depositListLoading: state.depositListLoading,
        withdrawListLoading: state.withdrawListLoading,
        withdrawInfoLoading: state.withdrawInfoLoading,
        withdrawBankListLoading: state.withdrawBankListLoading,
        withdrawBankInfoLoading: state.withdrawBankInfoLoading,
        withdrawCryptoListLoading: state.withdrawCryptoListLoading,
        withdrawCryptoInfoLoading: state.withdrawCryptoInfoLoading,
        withdrawPayfixListLoading: state.withdrawPayfixListLoading,
        withdrawPayfixInfoLoading: state.withdrawPayfixInfoLoading,
        withdrawCreditCardListLoading: state.withdrawCreditCardListLoading,
        withdrawCreditCardInfoLoading: state.withdrawCreditCardInfoLoading,
        paparaMailListLoading: state.paparaMailListLoading,
        reportListLoading: state.reportListLoading,
        paparaRegisterListLoading: state.paparaRegisterListLoading,
        paparaRegisterDetailsLoading: state.paparaRegisterDetailsLoading,
        payfixRegisterListLoading: state.payfixRegisterListLoading,
        payfixRegisterDetailsLoading: state.payfixRegisterDetailsLoading,
        bankRegisterListLoading: state.bankRegisterListLoading,
        bankRegisterDetailsLoading: state.bankRegisterDetailsLoading,
        banksSetList: state.banksSetList,
        banksSetListLoading: state.banksSetListLoading,
        cryptoRegisterListLoading: state.cryptoRegisterListLoading,
        cryptoRegisterDetailsLoading: state.cryptoRegisterDetailsLoading,
        creditCardRegisterListLoading: state.creditCardRegisterListLoading,
        creditCardRegisterDetailsLoading: state.creditCardRegisterDetailsLoading,
        newWithdrawMFTTransferList: state.newWithdrawMFTTransferList,
        newMFTTransferList: state.newMFTTransferList,
        newTransferMFTRegistersLoading: state.newTransferMFTRegistersLoading,
        mftRegisterListLoading: state.mftRegisterListLoading,
        mftRegisterDetailsLoading: state.mftRegisterDetailsLoading,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardInfoThunk.fulfilled, (state, action) => {
      state.dashboardInfo = action.payload;
    });
    builder.addCase(getCurrentPaparaListThunk.fulfilled, (state, action) => {
      state.currentPaparaList = action.payload;
    });
    builder.addCase(getFraudsThunk.fulfilled, (state, action) => {
      state.fraudList = action.payload;
      state.fraudListLoading = false;
    });
    builder.addCase(getFraudsThunk.pending, (state, action) => {
      state.fraudListLoading = true;
    });
    builder.addCase(getFraudsThunk.rejected, (state, action) => {
      state.fraudListLoading = false;
    });
    builder.addCase(getBankSetThunk.fulfilled, (state, action) => {
      state.banksSetList = action.payload;
      state.banksSetListLoading = false;
    });
    builder.addCase(getBankSetThunk.pending, (state, action) => {
      state.banksSetListLoading = true;
    });
    builder.addCase(getBankSetThunk.rejected, (state, action) => {
      state.banksSetListLoading = false;
    });
    builder.addCase(getRoleListThunk.fulfilled, (state, action) => {
      state.roleSelectList = action.payload;
    });
    builder.addCase(getUserGroupListThunk.fulfilled, (state, action) => {
      state.userGroupSelectList = action.payload;
    });
    builder.addCase(getRolesThunk.fulfilled, (state, action) => {
      state.roleList = action.payload;
      state.roleListLoading = false;
    });
    builder.addCase(getRolesThunk.pending, (state, action) => {
      state.roleListLoading = true;
    });
    builder.addCase(getRolesThunk.rejected, (state, action) => {
      state.roleListLoading = false;
    });
    builder.addCase(getRoleInfoThunk.fulfilled, (state, action) => {
      state.roleInfo = action.payload;
    });
    builder.addCase(getUsersThunk.fulfilled, (state, action) => {
      state.userList = action.payload;
      state.userListLoading = false;
    });
    builder.addCase(getUsersThunk.pending, (state, action) => {
      state.userListLoading = true;
    });
    builder.addCase(getUsersThunk.rejected, (state, action) => {
      state.userListLoading = false;
    });
    builder.addCase(getUserInfoThunk.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(getMerchantListThunk.fulfilled, (state, action) => {
      state.merchantSelectList = action.payload;
    });
    builder.addCase(getWholeMerchantListThunk.fulfilled, (state, action) => {
      state.merchantWholeSelectList = action.payload;
    });
    builder.addCase(getWholeUserListThunk.fulfilled, (state, action) => {
      state.userWholeSelectList = action.payload;
    });
    builder.addCase(getMerchantsThunk.fulfilled, (state, action) => {
      state.merchantList = action.payload;
      state.merchantListLoading = false;
    });
    builder.addCase(getMerchantsThunk.pending, (state, action) => {
      state.merchantListLoading = true;
    });
    builder.addCase(getMerchantsThunk.rejected, (state, action) => {
      state.merchantListLoading = false;
    });
    builder.addCase(getMerchantInfoThunk.fulfilled, (state, action) => {
      state.merchantInfo = action.payload;
    });
    builder.addCase(getNewTransfersThunk.fulfilled, (state, action) => {
      state.newTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newTransferList.find((t) => t.id === id)) {
          state.newTransferCreated = true;
        }
      });
      state.newTransferList = action.payload;
    });
    builder.addCase(getNewWithdrawBankTransfersThunk.fulfilled, (state, action) => {
      state.newWithdrawBankTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newWithdrawBankTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newTransferList.find((t) => t.id === id)) {
          state.newWithdrawBankTransferCreated = true;
        }
      });
      state.newWithdrawBankTransferList = action.payload;
    });

    builder.addCase(getNewWithdrawPaparaTransfersThunk.fulfilled, (state, action) => {
      state.newWithdrawPaparaTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newWithdrawPaparaTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newWithdrawPaparaTransferList.find((t) => t.id === id)) {
          state.newWithdrawPaparaTransferCreated = true;
        }
      });
      state.newWithdrawPaparaTransferList = action.payload;
    });

    builder.addCase(getNewWithdrawPayfixTransfersThunk.fulfilled, (state, action) => {
      state.newWithdrawPayfixTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newWithdrawPayfixTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newWithdrawPayfixTransferList.find((t) => t.id === id)) {
          state.newWithdrawPayfixTransferCreated = true;
        }
      });
      state.newWithdrawPayfixTransferList = action.payload;
    });

    builder.addCase(getNewWithdrawCryptoTransfersThunk.fulfilled, (state, action) => {
      state.newWithdrawCryptoTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newWithdrawCryptoTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newWithdrawCryptoTransferList.find((t) => t.id === id)) {
          state.newWithdrawCryptoTransferCreated = true;
        }
      });
      state.newWithdrawCryptoTransferList = action.payload;
    });

    builder.addCase(getNewCryptoTransfersThunk.fulfilled, (state, action) => {
      state.newCryptoTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newCryptoTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newCryptoTransferList.find((t) => t.id === id)) {
          state.newCryptoTransferCreated = true;
        }
      });
      state.newCryptoTransferList = action.payload;
    });

    builder.addCase(getNewWithdrawCreditCardTransfersThunk.fulfilled, (state, action) => {
      state.newWithdrawCreditCardTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newWithdrawCreditCardTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newWithdrawCreditCardTransferList.find((t) => t.id === id)) {
          state.newWithdrawCreditCardTransferCreated = true;
        }
      });
      state.newWithdrawCreditCardTransferList = action.payload;
    });

    builder.addCase(getNewCreditCardTransfersThunk.fulfilled, (state, action) => {
      state.newCreditCardTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newCreditCardTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newCreditCardTransferList.find((t) => t.id === id)) {
          state.newCreditCardTransferCreated = true;
        }
      });
      state.newCreditCardTransferList = action.payload;
    });

    builder.addCase(getNewPaparaTransfersThunk.fulfilled, (state, action) => {
      state.newPaparaTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newPaparaTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newPaparaTransferList.find((t) => t.id === id)) {
          state.newPaparaTransferCreated = true;
        }
      });
      state.newPaparaTransferList = action.payload;
    });


    builder.addCase(getNewPayfixTransfersThunk.fulfilled, (state, action) => {
      state.newPayfixTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newPayfixTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newPayfixTransferList.find((t) => t.id === id)) {
          state.newPayfixTransferCreated = true;
        }
      });
      state.newPayfixTransferList = action.payload;
    });

    builder.addCase(getNewTransferInfoThunk.fulfilled, (state, action) => {
      state.newTransferInfo = action.payload;
    });

    builder.addCase(
      getNewPaparaTransferInfoThunk.fulfilled,
      (state, action) => {
        state.newPaparaTransferInfo = action.payload;
      }
    );
    builder.addCase(
      getNewPayfixTransferInfoThunk.fulfilled,
      (state, action) => {
        state.newPayfixTransferInfo = action.payload;
      }
    );
    builder.addCase(
      getNewCryptoTransferInfoThunk.fulfilled,
      (state, action) => {
        state.newCryptoTransferInfo = action.payload;
      }
    );
    builder.addCase(
      getNewCreditCardTransferInfoThunk.fulfilled,
      (state, action) => {
        state.newCreditCardTransferInfo = action.payload;
      }
    );
    builder.addCase(
      getNewTransferBankRegistersThunk.fulfilled,
      (state, action) => {
        state.newTransferBankRegisters = action.payload;
        state.newTransferBankRegistersLoading = false;
      }
    );
    builder.addCase(
      getNewTransferBankRegistersThunk.pending,
      (state, action) => {
        state.newTransferBankRegistersLoading = true;
      }
    );
    builder.addCase(
      getNewTransferBankRegistersThunk.rejected,
      (state, action) => {
        state.newTransferBankRegistersLoading = false;
      }
    );
    builder.addCase(
      getNewPaparaTransferRegistersThunk.fulfilled,
      (state, action) => {
        state.newTransferPaparaRegisters = action.payload;
        state.newTransferPaparaRegistersLoading = false;
      }
    );
    builder.addCase(
      getNewPaparaTransferRegistersThunk.pending,
      (state, action) => {
        state.newTransferPaparaRegistersLoading = true;
      }
    );
    builder.addCase(
      getNewPaparaTransferRegistersThunk.rejected,
      (state, action) => {
        state.newTransferPaparaRegistersLoading = false;
      }
    );

    builder.addCase(
      getNewPayfixTransferRegistersThunk.fulfilled,
      (state, action) => {
        state.newTransferPayfixRegisters = action.payload;
        state.newTransferPayfixRegistersLoading = false;
      }
    );
    builder.addCase(
      getNewPayfixTransferRegistersThunk.pending,
      (state, action) => {
        state.newTransferPayfixRegistersLoading = true;
      }
    );
    builder.addCase(
      getNewPayfixTransferRegistersThunk.rejected,
      (state, action) => {
        state.newTransferPayfixRegistersLoading = false;
      }
    );
    builder.addCase(
      getNewCryptoTransferRegistersThunk.fulfilled,
      (state, action) => {
        state.newTransferCryptoRegisters = action.payload;
        state.newTransferCryptoRegistersLoading = false;
      }
    );
    builder.addCase(
      getNewCryptoTransferRegistersThunk.pending,
      (state, action) => {
        state.newTransferCryptoRegistersLoading = true;
      }
    );
    builder.addCase(
      getNewCryptoTransferRegistersThunk.rejected,
      (state, action) => {
        state.newTransferCryptoRegistersLoading = false;
      }
    );
    builder.addCase(
      getNewCreditCardTransferRegistersThunk.fulfilled,
      (state, action) => {
        state.newTransferCreditCardRegisters = action.payload;
        state.newTransferCreditCardRegistersLoading = false;
      }
    );
    builder.addCase(
      getNewCreditCardTransferRegistersThunk.pending,
      (state, action) => {
        state.newTransferCreditCardRegistersLoading = true;
      }
    );
    builder.addCase(
      getNewCreditCardTransferRegistersThunk.rejected,
      (state, action) => {
        state.newTransferCreditCardRegistersLoading = false;
      }
    );
    builder.addCase(getDepositsThunk.fulfilled, (state, action) => {
      state.depositList = action.payload;
      state.depositListLoading = false;
    });
    builder.addCase(getDepositsThunk.pending, (state, action) => {
      state.depositListLoading = true;
    });
    builder.addCase(getDepositsThunk.rejected, (state, action) => {
      state.depositListLoading = false;
    });
    builder.addCase(getDepositInfoThunk.fulfilled, (state, action) => {
      state.depositInfo = action.payload;
    });

    builder.addCase(getWithdrawsBankThunk.fulfilled, (state, action) => {
      state.withdrawBankList = action.payload;
      state.withdrawListLoading = false;
    });
    builder.addCase(getWithdrawsBankThunk.pending, (state, action) => {
      state.withdrawListLoading = true;
    });
    builder.addCase(getWithdrawsBankThunk.rejected, (state, action) => {
      state.withdrawListLoading = false;
    });
    builder.addCase(getWithdrawBankInfoThunk.fulfilled, (state, action) => {
      state.withdrawBankInfo = action.payload;
      state.withdrawInfoLoading = false;
    });
    builder.addCase(getWithdrawBankInfoThunk.pending, (state, action) => {
      state.withdrawInfoLoading = true;
    });
    builder.addCase(getWithdrawBankInfoThunk.rejected, (state, action) => {
      state.withdrawInfoLoading = false;
    });

    builder.addCase(getWithdrawsPaparaThunk.fulfilled, (state, action) => {
      state.withdrawList = action.payload;
      state.withdrawListLoading = false;
    });
    builder.addCase(getWithdrawsPaparaThunk.pending, (state, action) => {
      state.withdrawListLoading = true;
    });
    builder.addCase(getWithdrawsPaparaThunk.rejected, (state, action) => {
      state.withdrawListLoading = false;
    });

    builder.addCase(getWithdrawsPayfixThunk.fulfilled, (state, action) => {
      state.withdrawPayfixList = action.payload;
      state.withdrawPayfixListLoading = false;
    });
    builder.addCase(getWithdrawsPayfixThunk.pending, (state, action) => {
      state.withdrawPayfixListLoading = true;
    });
    builder.addCase(getWithdrawsPayfixThunk.rejected, (state, action) => {
      state.withdrawPayfixListLoading = false;
    });

    builder.addCase(getWithdrawPaparaInfoThunk.fulfilled, (state, action) => {
      state.withdrawPaparaInfo = action.payload;
      state.withdrawInfoLoading = false;
    });
    builder.addCase(getWithdrawPaparaInfoThunk.pending, (state, action) => {
      state.withdrawInfoLoading = true;
    });
    builder.addCase(getWithdrawPaparaInfoThunk.rejected, (state, action) => {
      state.withdrawInfoLoading = false;
    });
   
    builder.addCase(getWithdrawPayfixInfoThunk.fulfilled, (state, action) => {
      state.withdrawPayfixInfo = action.payload;
      state.withdrawPayfixInfoLoading = false;
    });
    builder.addCase(getWithdrawPayfixInfoThunk.pending, (state, action) => {
      state.withdrawPayfixInfoLoading = true;
    });
    builder.addCase(getWithdrawPayfixInfoThunk.rejected, (state, action) => {
      state.withdrawPayfixInfoLoading = false;
    });
    builder.addCase(getWithdrawsCryptoThunk.fulfilled, (state, action) => {
      state.withdrawCryptoList = action.payload;
      state.withdrawCryptoListLoading = false;
    });
    builder.addCase(getWithdrawsCryptoThunk.pending, (state, action) => {
      state.withdrawCryptoListLoading = true;
    });
    builder.addCase(getWithdrawsCryptoThunk.rejected, (state, action) => {
      state.withdrawCryptoListLoading = false;
    });
    builder.addCase(getWithdrawCryptoInfoThunk.fulfilled, (state, action) => {
      state.withdrawCryptoInfo = action.payload;
      state.withdrawCryptoInfoLoading = false;
    });
    builder.addCase(getWithdrawCryptoInfoThunk.pending, (state, action) => {
      state.withdrawCryptoInfoLoading = true;
    });
    builder.addCase(getWithdrawCryptoInfoThunk.rejected, (state, action) => {
      state.withdrawCryptoInfoLoading = false;
    });
    builder.addCase(getWithdrawsCreditCardThunk.fulfilled, (state, action) => {
      state.withdrawCreditCardList = action.payload;
      state.withdrawCreditCardListLoading = false;
    });
    builder.addCase(getWithdrawsCreditCardThunk.pending, (state, action) => {
      state.withdrawCreditCardListLoading = true;
    });
    builder.addCase(getWithdrawsCreditCardThunk.rejected, (state, action) => {
      state.withdrawCreditCardListLoading = false;
    });
    builder.addCase(getWithdrawCreditCardInfoThunk.fulfilled, (state, action) => {
      state.withdrawCreditCardInfo = action.payload;
      state.withdrawCreditCardInfoLoading = false;
    });
    builder.addCase(getWithdrawCreditCardInfoThunk.pending, (state, action) => {
      state.withdrawCreditCardInfoLoading = true;
    });
    builder.addCase(getWithdrawCreditCardInfoThunk.rejected, (state, action) => {
      state.withdrawCreditCardInfoLoading = false;
    });
    builder.addCase(getPaparaMailsThunk.fulfilled, (state, action) => {
      state.paparaMailList = action.payload;
      state.paparaMailListLoading = false;
    });
    builder.addCase(getPaparaMailsThunk.pending, (state, action) => {
      state.paparaMailListLoading = true;
    });
    builder.addCase(getPaparaMailsThunk.rejected, (state, action) => {
      state.paparaMailListLoading = false;
    });
    builder.addCase(getReportsThunk.fulfilled, (state, action) => {
      state.reportList = action.payload;
      state.reportListLoading = false;
    });
    builder.addCase(getReportsThunk.pending, (state, action) => {
      state.reportListLoading = true;
    });
    builder.addCase(getReportsThunk.rejected, (state, action) => {
      state.reportListLoading = false;
    });
    builder.addCase(getPaparaRegistersThunk.fulfilled, (state, action) => {
      state.paparaRegisterList = action.payload;
      state.paparaRegisterListLoading = false;
    });
    builder.addCase(getPaparaRegistersThunk.pending, (state, action) => {
      state.paparaRegisterListLoading = true;
    });
    builder.addCase(getPaparaRegistersThunk.rejected, (state, action) => {
      state.paparaRegisterListLoading = false;
    });
    builder.addCase(getPaparaRegisterListThunk.fulfilled, (state, action) => {
      state.paparaRegisterSelectList = [];
      state.paparaRegisterSelectListDeposit = [];
      state.paparaRegisterSelectListWithdraw = [];
      action.payload.forEach((entry) => {
        const { id, isim, bakiye, durum, yatirim_adet, cekim_adet } = entry;
        if (durum) {
          state.paparaRegisterSelectList.push({
            id,
            text: isim + ' - ' + bakiye + ' TRY',
          });
          state.paparaRegisterSelectListDeposit.push({
            id,
            text:
              isim +
              ' - ' +
              bakiye +
              ' TRY' +
              ' (Yatırım: ' +
              yatirim_adet +
              ')',
          });
          state.paparaRegisterSelectListWithdraw.push({
            id,
            text:
              isim + ' - ' + bakiye + ' TRY' + ' (Çekim: ' + cekim_adet + ')',
          });
        }
      });
    });
    builder.addCase(getPayFixRegistersThunk.fulfilled, (state, action) => {
      state.payfixRegisterList = action.payload;
      state.payfixRegisterListLoading = false;
    });
    builder.addCase(getPayFixRegistersThunk.pending, (state, action) => {
      state.payfixRegisterListLoading = true;
    });
    builder.addCase(getPayFixRegistersThunk.rejected, (state, action) => {
      state.payfixRegisterListLoading = false;
    });
    builder.addCase(getPayFixRegisterListThunk.fulfilled, (state, action) => {
      state.payfixRegisterSelectList = [];
      state.payfixRegisterSelectListDeposit = [];
      state.payfixRegisterSelectListWithdraw = [];
      action.payload.forEach((entry) => {
        const { id, isim, bakiye, durum, yatirim_adet, cekim_adet } = entry;
        if (durum) {
          state.payfixRegisterSelectList.push({
            id,
            text: isim + ' - ' + bakiye + ' TRY',
          });
          state.payfixRegisterSelectListDeposit.push({
            id,
            text:
              isim +
              ' - ' +
              bakiye +
              ' TRY' +
              ' (Yatırım: ' +
              yatirim_adet +
              ')',
          });
          state.payfixRegisterSelectListWithdraw.push({
            id,
            text:
              isim + ' - ' + bakiye + ' TRY' + ' (Çekim: ' + cekim_adet + ')',
          });
        }
      });
    });
    builder.addCase(getCryptoRegistersThunk.fulfilled, (state, action) => {
      state.cryptoRegisterList = action.payload;
      state.cryptoRegisterListLoading = false;
    });
    builder.addCase(getCryptoRegistersThunk.pending, (state, action) => {
      state.cryptoRegisterListLoading = true;
    });
    builder.addCase(getCryptoRegistersThunk.rejected, (state, action) => {
      state.cryptoRegisterListLoading = false;
    });
    builder.addCase(getCryptoRegisterListThunk.fulfilled, (state, action) => {
      state.cryptoRegisterSelectList = [];
      state.cryptoRegisterSelectListDeposit = [];
      state.cryptoRegisterSelectListWithdraw = [];
      action.payload.forEach((entry) => {
        const { id, isim, bakiye, durum, yatirim_adet, cekim_adet } = entry;
        if (durum) {
          state.cryptoRegisterSelectList.push({
            id,
            text: isim + ' - ' + bakiye + ' TRY',
          });
          state.cryptoRegisterSelectListDeposit.push({
            id,
            text:
              isim +
              ' - ' +
              bakiye +
              ' TRY' +
              ' (Yatırım: ' +
              yatirim_adet +
              ')',
          });
          state.cryptoRegisterSelectListWithdraw.push({
            id,
            text:
              isim + ' - ' + bakiye + ' TRY' + ' (Çekim: ' + cekim_adet + ')',
          });
        }
      });
    });
    builder.addCase(getCreditCardRegistersThunk.fulfilled, (state, action) => {
      state.creditCardRegisterList = action.payload;
      state.creditCardRegisterListLoading = false;
    });
    builder.addCase(getCreditCardRegistersThunk.pending, (state, action) => {
      state.creditCardRegisterListLoading = true;
    });
    builder.addCase(getCreditCardRegistersThunk.rejected, (state, action) => {
      state.creditCardRegisterListLoading = false;
    });
    builder.addCase(getCreditCardRegisterListThunk.fulfilled, (state, action) => {
      state.creditCardRegisterSelectList = [];
      state.creditCardRegisterSelectListDeposit = [];
      state.creditCardRegisterSelectListWithdraw = [];
      action.payload.forEach((entry) => {
        const { id, isim, bakiye, durum, yatirim_adet, cekim_adet } = entry;
        if (durum) {
          state.creditCardRegisterSelectList.push({
            id,
            text: isim + ' - ' + bakiye + ' TRY',
          });
          state.creditCardRegisterSelectListDeposit.push({
            id,
            text:
              isim +
              ' - ' +
              bakiye +
              ' TRY' +
              ' (Yatırım: ' +
              yatirim_adet +
              ')',
          });
          state.creditCardRegisterSelectListWithdraw.push({
            id,
            text:
              isim + ' - ' + bakiye + ' TRY' + ' (Çekim: ' + cekim_adet + ')',
          });
        }
      });
    });
    builder.addCase(getBankRegisterListThunk.fulfilled, (state, action) => {
      state.bankRegisterSelectList = [];
      state.bankRegisterSelectListDeposit = [];
      state.bankRegisterSelectListWithdraw = [];
      action.payload.forEach((entry) => {
        const { id, isim, banka, bakiye, durum, yatirim_adet, cekim_adet } =
          entry;
        if (durum) {
          state.bankRegisterSelectList.push({
            id,
            text: isim + ' - ' + bakiye + ' TRY - ' + banka,
          });
          state.bankRegisterSelectListDeposit.push({
            id,
            text:
              isim +
              ' - ' +
              bakiye +
              ' TRY - ' +
              banka +
              ' (Yatırım: ' +
              yatirim_adet +
              ')',
          });
          state.bankRegisterSelectListWithdraw.push({
            id,
            text:
              isim +
              ' - ' +
              bakiye +
              ' TRY - ' +
              banka +
              ' (Çekim: ' +
              cekim_adet +
              ')',
          });
        }
      });
    });
    builder.addCase(getBankListThunk.fulfilled, (state, action) => {
      state.bankSelectList = action.payload;
    });
    builder.addCase(getBanksThunk.fulfilled, (state, action) => {
      state.bankList = action.payload;
      state.bankListLoading = false;
    });
    builder.addCase(getBanksThunk.pending, (state, action) => {
      state.bankListLoading = true;
    });
    builder.addCase(getBanksThunk.rejected, (state, action) => {
      state.bankListLoading = false;
    });
    builder.addCase(
      getPaparaRegisterDetailsThunk.fulfilled,
      (state, action) => {
        state.paparaRegisterDetailsList = action.payload;
        state.paparaRegisterDetailsLoading = false;
      }
    );
    builder.addCase(getPaparaRegisterDetailsThunk.pending, (state, action) => {
      state.paparaRegisterDetailsLoading = true;
    });
    builder.addCase(getPaparaRegisterDetailsThunk.rejected, (state, action) => {
      state.paparaRegisterDetailsLoading = false;
    });
    builder.addCase(
      getPayFixRegisterDetailsThunk.fulfilled,
      (state, action) => {
        state.payfixRegisterDetailsList = action.payload;
        state.payfixRegisterDetailsLoading = false;
      }
    );
    builder.addCase(getPayFixRegisterDetailsThunk.pending, (state, action) => {
      state.payfixRegisterDetailsLoading = true;
    });
    builder.addCase(getPayFixRegisterDetailsThunk.rejected, (state, action) => {
      state.payfixRegisterDetailsLoading = false;
    });
    builder.addCase(
      getCryptoRegisterDetailsThunk.fulfilled,
      (state, action) => {
        state.cryptoRegisterDetailsList = action.payload;
        state.cryptoRegisterDetailsLoading = false;
      }
    );
    builder.addCase(getCryptoRegisterDetailsThunk.pending, (state, action) => {
      state.cryptoRegisterDetailsLoading = true;
    });
    builder.addCase(getCryptoRegisterDetailsThunk.rejected, (state, action) => {
      state.cryptoRegisterDetailsLoading = false;
    });
    builder.addCase(
      getCreditCardRegisterDetailsThunk.fulfilled,
      (state, action) => {
        state.creditCardRegisterDetailsList = action.payload;
        state.creditCardRegisterDetailsLoading = false;
      }
    );
    builder.addCase(getCreditCardRegisterDetailsThunk.pending, (state, action) => {
      state.creditCardRegisterDetailsLoading = true;
    });
    builder.addCase(getCreditCardRegisterDetailsThunk.rejected, (state, action) => {
      state.creditCardRegisterDetailsLoading = false;
    });
    builder.addCase(getBankRegisterDetailsThunk.fulfilled, (state, action) => {
      state.bankRegisterDetailsList = action.payload;
      state.bankRegisterDetailsLoading = false;
    });
    builder.addCase(getBankRegisterDetailsThunk.pending, (state, action) => {
      state.bankRegisterDetailsLoading = true;
    });
    builder.addCase(getBankRegisterDetailsThunk.rejected, (state, action) => {
      state.bankRegisterDetailsLoading = false;
    });
    builder.addCase(getPaparaRegisterInfoThunk.fulfilled, (state, action) => {
      state.paparaRegisterInfo = action.payload;
    });
    builder.addCase(getPayFixRegisterInfoThunk.fulfilled, (state, action) => {
      state.payfixRegisterInfo = action.payload;
    });
    builder.addCase(getCryptoRegisterInfoThunk.fulfilled, (state, action) => {
      state.cryptoRegisterInfo = action.payload;
    });
    builder.addCase(getCreditCardRegisterInfoThunk.fulfilled, (state, action) => {
      state.creditCardRegisterInfo = action.payload;
    });
    builder.addCase(getBankRegistersThunk.fulfilled, (state, action) => {
      state.bankRegisterList = action.payload;
      state.bankRegisterListLoading = false;
    });
    builder.addCase(getBankRegistersThunk.pending, (state, action) => {
      state.bankRegisterListLoading = true;
    });
    builder.addCase(getBankRegistersThunk.rejected, (state, action) => {
      state.bankRegisterListLoading = false;
    });
    builder.addCase(getBankRegisterInfoThunk.fulfilled, (state, action) => {
      state.bankRegisterInfo = action.payload;
    });

    builder.addCase(getNewWithdrawMFTTransfersThunk.fulfilled, (state, action) => {
      state.newWithdrawMFTTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newWithdrawMFTTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newWithdrawMFTTransferList.find((t) => t.id === id)) {
          state.newWithdrawMFTTransferCreated = true;
        }
      });
      state.newWithdrawMFTTransferList = action.payload;
    });

    builder.addCase(getNewMFTTransfersThunk.fulfilled, (state, action) => {
      state.newMFTTransferList.forEach((transfer) => {
        const id = transfer.id;
        const newT = action.payload.find((t) => t.id === id);
        if (transfer.durum !== 2 && newT?.durum === 2) {
          state.newMFTTransferStatusChanged = true;
        }
      });
      action.payload.forEach((transfer) => {
        const id = transfer.id;
        if (!state.newMFTTransferList.find((t) => t.id === id)) {
          state.newMFTTransferCreated = true;
        }
      });
      state.newMFTTransferList = action.payload;
    });

    builder.addCase(getNewMFTTransferInfoThunk.fulfilled,
      (state, action) => {
        state.newMFTTransferInfo = action.payload;
      }
    );

    builder.addCase(getNewMFTTransferRegistersThunk.fulfilled,
      (state, action) => {
        state.newTransferMFTRegisters = action.payload;
        state.newTransferMFTRegistersLoading = false;
      }
    );
    builder.addCase(getNewMFTTransferRegistersThunk.pending,
      (state, action) => {
        state.newTransferMFTRegistersLoading = true;
      }
    );
    builder.addCase(getNewMFTTransferRegistersThunk.rejected,
      (state, action) => {
        state.newTransferMFTRegistersLoading = false;
      }
    );

    builder.addCase(getWithdrawsMFTThunk.fulfilled, (state, action) => {
      state.withdrawMFTList = action.payload;
      state.withdrawMFTListLoading = false;
    });

    builder.addCase(getWithdrawsMFTThunk.pending, (state, action) => {
      state.withdrawMFTListLoading = true;
    });

    builder.addCase(getWithdrawsMFTThunk.rejected, (state, action) => {
      state.withdrawMFTListLoading = false;
    });

    builder.addCase(getWithdrawMFTInfoThunk.fulfilled, (state, action) => {
      state.withdrawMFTInfo = action.payload;
      state.withdrawInfoLoading = false;
    });

    builder.addCase(getWithdrawMFTInfoThunk.pending, (state, action) => {
      state.withdrawInfoLoading = true;
    });

    builder.addCase(getWithdrawMFTInfoThunk.rejected, (state, action) => {
      state.withdrawInfoLoading = false;
    });

    builder.addCase(getMFTRegistersThunk.fulfilled, (state, action) => {
      state.mftRegisterList = action.payload;
      state.mftRegisterListLoading = false;
    });

    builder.addCase(getMFTRegistersThunk.pending, (state, action) => {
      state.mftRegisterListLoading = true;
    });

    builder.addCase(getMFTRegistersThunk.rejected, (state, action) => {
      state.mftRegisterListLoading = false;
    });

    builder.addCase(getMFTRegisterListThunk.fulfilled, (state, action) => {
      state.mftRegisterSelectList = [];
      state.mftRegisterSelectListDeposit = [];
      state.mftRegisterSelectListWithdraw = [];
      action.payload.forEach((entry) => {
        const { id, isim, bakiye, durum, yatirim_adet, cekim_adet } = entry;
        if (durum) {
          state.mftRegisterSelectList.push({
            id,
            text: isim + ' - ' + bakiye + ' TRY',
          });
          state.mftRegisterSelectListDeposit.push({
            id,
            text:
              isim +
              ' - ' +
              bakiye +
              ' TRY' +
              ' (Yatırım: ' +
              yatirim_adet +
              ')',
          });
          state.mftRegisterSelectListWithdraw.push({
            id,
            text:
              isim + ' - ' + bakiye + ' TRY' + ' (Çekim: ' + cekim_adet + ')',
          });
        }
      });
    });

    builder.addCase(
      getMFTRegisterDetailsThunk.fulfilled,
      (state, action) => {
        state.mftRegisterDetailsList = action.payload;
        state.mftRegisterDetailsLoading = false;
      }
    );

    builder.addCase(getMFTRegisterDetailsThunk.pending, (state, action) => {
      state.mftRegisterDetailsLoading = true;
    });

    builder.addCase(getMFTRegisterDetailsThunk.rejected, (state, action) => {
      state.mftRegisterDetailsLoading = false;
    });

    builder.addCase(getMFTRegisterInfoThunk.fulfilled, (state, action) => {
      state.mftRegisterInfo = action.payload;
    });
  },
});

export const actions = globalSlice.actions;

export default globalSlice.reducer;
